import { httpClient } from '@abaninja/util-http-client';
import { createCancellablePromise } from '@abaninja/util-store';

import type { Account, AccountRequest } from '../@types';

export function getAccount(accountUuid: string) {
  return createCancellablePromise(
    ({ signal }) => httpClient.get<Payload<Account>>(`/accounts/v1/accounts/${accountUuid}`, { signal }),
  );
}

export function createAccount(data: AccountRequest) {
  return httpClient.post<Payload<Account>>('/accounts/v1/accounts', data);
}

export function deleteAccount(accountUuid: string) {
  return httpClient.delete(`/accounts/v1/accounts/${accountUuid}`);
}

export function updateAccount(accountUuid: string, data: AccountRequest) {
  return httpClient.patch<Payload<Account>>(`/accounts/v1/accounts/${accountUuid}`, data);
}
